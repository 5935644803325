import type { IArticleCard } from '../ArticleCard'
import { ArticleCard } from '../ArticleCard'
import { styles } from './styles'

export interface ICarouselArticleItem {
  index: number
  'data-active'?: boolean
  isCard?: boolean
  isCarousel?: boolean
  isVideo?: boolean
  isViewportMobile?: boolean
  link: string
  pictures: IArticleCard['pictures']
  title?: string
  isBlured?: boolean
  hasMultiLive: boolean
  duration?: number
  onClick?: () => void
  picturePriority?: 'auto' | 'high'
  pictureImportance?: 'auto' | 'high'
  ratio?: string
}

export const CarouselArticleItem: React.FC<ICarouselArticleItem> = ({
  index,
  isCard,
  isCarousel,
  isVideo,
  link,
  pictures,
  title = null,
  isBlured,
  hasMultiLive,
  onClick,
  picturePriority,
  pictureImportance,
  duration,
  ratio,
}) => {
  return (
    <>
      <ArticleCard
        index={index}
        isCard={isCard}
        isCarousel={isCarousel}
        isVideo={isVideo}
        link={link}
        pictures={pictures}
        title={title}
        isBlured={isBlured}
        hasMultiLive={hasMultiLive}
        onClick={onClick}
        picturePriority={picturePriority}
        pictureImportance={pictureImportance}
        ratio={ratio}
        duration={duration}
      />
      <style jsx global>
        {styles}
      </style>
    </>
  )
}
