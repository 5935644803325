import css from 'styled-jsx/css'
import { mixins } from '../../../styles'

export const styles = css.global`
  .swiper-wrapper {
    overflow: hidden;
  }
  .swiper-wrapper .ArticleCard__Content {
    background-color: #000964;
    overflow: hidden;
    padding: 24px;
  }

  @media ${mixins.mediaQuery.tabletPaysage} {
    .swiper-wrapper .ArticleCard__Title {
      font-size: 25px;
    }
  }

  .swiper-wrapper .swiper-slide-active .ArticleCard__Title {
    margin: 0;
  }

  .ArticleCard__Carousel__Img__Animation img {
    animation: ArticleCardLeftZoom 6s forwards;
  }

  @media ${mixins.mediaQuery.tablet} {
    .swiper-wrapper .swiper-slide-active .ArticleCard__Title {
      animation: none;
    }
    .swiper-wrapper .ArticleCard__Content img {
      animation: none;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
