import { durationToTimer } from '../../../helpers/durationToTimer'
import { theme } from '../../../styles'

export interface IDurationLabel {
  duration: number
}

export function DurationLabel({
  duration,
  ...rest
}: IDurationLabel & React.AllHTMLAttributes<HTMLParagraphElement>): JSX.Element {
  if (!duration || duration <= 0) {
    return null
  }

  return (
    <>
      <p {...rest}>
        <span className="sr-only">{'Durée: '}</span>
        {durationToTimer(duration)}
      </p>

      <style jsx>{`
        p {
          background-color: ${theme.colors.white};
          padding: 3px 6px 0;
          border-radius: 50px;
          user-select: none;

          font-size: 13px;
          font-weight: 600;
          line-height: 145%;
        }
      `}</style>
    </>
  )
}
