import type { Block } from '../../../types/block'
import type { IPictureElement } from '../../../types/pictures'
import { PictureElementList } from '../../atoms/PictureElementList'

export interface IPicture extends Block {
  alt?: string
  copyright?: string
  elementList: IPictureElement[]
  lazyload?: boolean
  placeholderTheme?: 'dark' | 'light'
  withPreload?: boolean
  caption?: string
  className?: string
  withFullScreen?: boolean
}

export function Picture({
  className,
  alt,
  copyright,
  caption,
  elementList,
  lazyload,
  placeholderTheme,
  withPreload,
  withFullScreen = false,
}: IPicture): JSX.Element {
  return (
    <>
      <figure className={`Picture${className ? ` ${className}` : ''}`}>
        <PictureElementList
          elementList={elementList}
          lazyload={lazyload}
          alt={alt ?? caption}
          placeholderTheme={placeholderTheme}
          withPreload={withPreload}
          withFullScreen={withFullScreen}
        />
        {(caption || copyright) && (
          <figcaption className="Picture__Figcaption flex">{`${caption ?? ''}${
            caption && copyright ? ' - ' : ''
          }${copyright ?? ''}`}</figcaption>
        )}
      </figure>
      <style jsx>{`
        figure {
          position: relative;
          margin: 0;
        }

        .Picture__Figcaption {
          font-size: 14px;
          margin-top: 5px;
        }
      `}</style>
    </>
  )
}
